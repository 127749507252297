/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Penthouses = {

    // All pages
    'common': {
      // JavaScript to be fired on all pages
      init: function () {
        console.log('Initialize site header waypoint');
        var waypoints = $('.js-site-header').waypoint(function (direction) {
          $('.js-site-header').toggleClass('site-header--scrolled');
        }, {
          offset: '-5%'
        });

        // nav animation
        console.log('Bind click event to nav toggle');
        $('.js-drawer-nav-toggle').on('click', function () {
          $('.js-drawer-nav').toggleClass('js-drawer-nav-open');
          event.preventDefault();
        });

        // cities dropdowns
        function citiesDropdownPositioning() {
          var dropdownCities = $('.js-header-dropdown--cities');
          var dropdownCitiesArrow = $('.js-header-dropdown--cities .site-header-dropdown__arrow');
          var linkCities = $('.site-header__nav--cities');
          var linkCitiesAnchor = $('.site-header__nav--cities a');
          var linkCitiesTop = $(linkCities).offset().top + $(linkCities).outerHeight(true) + 20;
          var linkCitiesRight = $(window).width() - $(linkCities).offset().left - $(linkCities).outerWidth(true);
          var linkCitiesArrowOffset = $(linkCities).outerWidth(true) / 2 - 8;

          $(dropdownCities).css({
            'top': linkCitiesTop,
            'right': linkCitiesRight
          });

          $(dropdownCitiesArrow).css({
            'right': linkCitiesArrowOffset
          });

          // Set dropdown as active
          $(linkCitiesAnchor).click(function (event) {
            event.stopPropagation();
            event.preventDefault();
            $(dropdownCities).toggleClass('js-active');
          });

          $(document).click(function () {
            $(dropdownCities).removeClass('js-active');
          });

        }

        function hideSearchMenu() {
          $(document).click(function() {
            $('.js-header-dropdown--search').removeClass('js-active');
          });
        }

         // Search dropdowns
         $('#property_address').focus(function () {
           $('.js-header-dropdown--search').addClass('js-active');
           setTimeout(function(){ hideSearchMenu(); }, 1000);
         });


        // Typekit
        try {
          Typekit.load({
            loading: function () {
              // JavaScript to execute when fonts start loading
            },
            active: function () {
              // JavaScript to execute when fonts become active
              citiesDropdownPositioning();
            },
            inactive: function () {
              // JavaScript to execute when fonts become inactive
            }
          })
        } catch (e) {
        }

        /* Search Page Control Panel UI */

        // Get Post Type From URL

        function getParameterByName(name, url) {
          if (!url) url = window.location.href;
          name = name.replace(/[\[\]]/g, "\\$&");
          var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
          if (!results) return null;
          if (!results[2]) return '';
          return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        // Save post type from url to variable and make first letter of post type - uppercase

        var postType = getParameterByName('post_type').charAt(0).toUpperCase() + getParameterByName('post_type').slice(1).toLowerCase();

        // Save Dom Elements to variables

        // Sliders From Search Control UI

        var sliderPricerange = document.getElementById('slider-pricerange'),
          sliderBedrooms = document.getElementById('slider-bedrooms'),
          sliderBathrooms = document.getElementById('slider-bathrooms');

        // Select elements from EPL plugin

        var propertyBedroomsMin = document.getElementById('property_bedrooms_min'),
          propertyBathrooms = document.getElementById('property_bathrooms'),
          propertyPriceFrom = document.getElementById('property_price_from'),
          propertyPriceTo = document.getElementById('property_price_to'),
          propertyCategory = postType !== 'Rural' ? document.getElementById('property_category') : document.getElementById('property_rural_category');

        // First and Last values of Select options from EPL plugin

        var priceFirstValue = parseInt($("#property_price_from option:eq(1)").val()),
          priceLastValue = parseInt($("#property_price_from option:last-child").val()),
          bathroomsValue = parseInt($("#property_bathrooms option:last-child").val()),
          bedroomsValue = parseInt($("#property_bedrooms_min option:last-child").val());

        // If Select element has selected option, save it to variable
        // TODO: Prevent strange counting

        var startPrice = propertyPriceFrom.options[propertyPriceFrom.selectedIndex].value !== "" ? (propertyPriceFrom.options[propertyPriceFrom.selectedIndex].value) : priceFirstValue,
          endPrice = propertyPriceTo.options[propertyPriceTo.selectedIndex].value !== "" ? (propertyPriceTo.options[propertyPriceTo.selectedIndex].value) : priceLastValue,
          // Last two variables we save only if post type other than land
          startBedrooms = postType !== 'Land' && propertyBedroomsMin.options[propertyBedroomsMin.selectedIndex].value !== "" ? (propertyBedroomsMin.options[propertyBedroomsMin.selectedIndex].value) : 1,
          startBathrooms = postType !== 'Land' && propertyBathrooms.options[propertyBathrooms.selectedIndex].value !== "" ? (propertyBathrooms.options[propertyBathrooms.selectedIndex].value) : 1;

        // Creating Sliders For Search Control UI

        // Price Range Slider
        // TODO: Prevent strange counting
        noUiSlider.create(sliderPricerange, {
          start: [startPrice, endPrice],
          connect: true,
          direction: 'ltr',
          step: postType !== 'Rental' ? 100000 : 100,
          range: {
            'min': priceFirstValue,
            'max': priceLastValue
          },
          tooltips: true,
          format: ({
            to: function (value) {
              var sliderVal = value.toFixed(0);
              return sliderVal >= 0 && sliderVal <= 999 ? sliderVal + '$' : sliderVal >= 1000 && sliderVal <= 999999 ? sliderVal / 1000 + 'K' : sliderVal / 1000000 + 'M+';
            },
            from: function (value) {
              return value;
            },
            decimals: 0,
            postfix: 'K',
            thousand: '.'
          })
        });

        // Bedrooms Slider

        if (postType !== 'Land') {

          noUiSlider.create(sliderBedrooms, {
            start: startBedrooms,
            connect: [true, false],
            direction: 'ltr',
            step: 1,
            range: {
              'min': 1,
              'max': bedroomsValue
            },
            tooltips: true,
            format: wNumb({
              decimals: 0,
              postfix: '+'
            })
          });

          // Bathrooms Slider

          noUiSlider.create(sliderBathrooms, {
            start: startBathrooms,
            connect: [true, false],
            direction: 'ltr',
            step: 1,
            range: {
              'min': 1,
              'max': bathroomsValue
            },
            tooltips: true,
            format: wNumb({
              decimals: 0,
              postfix: '+'
            })
          });
        }

        // Sliders Event Listeners

        // Listen To Price Range Slider

        sliderPricerange.noUiSlider.on('slide', function (values, handle) {

          var value = parseFloat(values[handle]);

          // TODO: Prevent strange counting

          if (handle) {
            propertyPriceTo.value = postType === 'Rental' && value >= 99 ? value :
              postType === 'Rental' && value <= 99 ? value * 1000 :
                postType !== 'Rental' && value >= 99 ? value * 1000 :
                  postType !== 'Rental' && value <= 99 ? "Any" : '0';
            propertyPriceTo.focus();
            $('input[type="submit"].epl-search-btn').trigger('click');
          } else {
            propertyPriceFrom.value = postType === 'Rental' && value >= 99 ? value :
              postType === 'Rental' && value <= 99 ? value * 1000 :
                postType !== 'Rental' && value >= 99 ? value * 1000 :
                  postType !== 'Rental' && value <= 99 ? "Any" : '0';
            propertyPriceFrom.focus();
            $('input[type="submit"].epl-search-btn').trigger('click');
          }
        });

        // Listen To Bedrooms and Bathrooms slider, if post type other than land

        if (postType !== 'Land') {
          sliderBedrooms.noUiSlider.on('slide', function (values) {
            propertyBedroomsMin.value = parseInt(values);
            propertyBedroomsMin.focus();
            $('input[type="submit"].epl-search-btn').trigger('click');
          });

          sliderBathrooms.noUiSlider.on('slide', function (values) {
            propertyBathrooms.value = parseInt(values);
            propertyBathrooms.focus();
            $('input[type="submit"].epl-search-btn').trigger('click');
          });
        }

        // Creating Select2 Dropdowns and setting value if exist

        $(document).ready(function () {

          // Create Listing Type Dropdown

          $('#listing-type-criteria').select2({
            placeholder: "LISTING TYPE",
            minimumResultsForSearch: -1,
            width: "9%"
          }).val(postType).trigger('change');

          // Create if post type other than land, create Home Type and More Dropdowns

          if (postType !== 'Land') {
            $('#home-type-criteria').select2({
              placeholder: "HOME TYPE",
              minimumResultsForSearch: -1,
              width: "9%"
            }).val(propertyCategory.value).trigger("change");

            var selected = [];
            $('.epl-search-other input:checked').each(function () {
              selected.push($(this).attr('name'));
            });

            $('#more-criteria').select2({
              placeholder: "MORE",
              minimumResultsForSearch: -1,
              width: "9%",
              closeOnSelect:false
            }).val(selected).trigger("change");

          }

          // Create Sort Dropdown - converts existing EPL sorting select to Select2 Dropdown

          $('#epl-sort-listings').select2({
            minimumResultsForSearch: -1
          });

          // Listen To Select2 Dropdowns

          // Listen To Listing Type Dropdown

          $('#listing-type-criteria').on("select2:select", function (event) {
            var selVal = $(event.currentTarget).find("option:selected").val().toString().toLowerCase();
            $('input[type="hidden"]#post_type').attr('value', selVal);
            // propertyPriceFrom.focus();
            $('input[type="submit"].epl-search-btn:first').trigger('click');
          });

          // If post type other than land listen to home type and more dropdowns

          if (postType !== 'Land') {

            $('#home-type-criteria').on("select2:select", function (event) {
              propertyCategory.value = $(event.currentTarget).find("option:selected").val().toString();
              propertyCategory.focus();
              $('input[type="submit"].epl-search-btn').trigger('click');
            });

            $('#more-criteria').on("select2:select", function (e) {
              document.getElementById(e.params.data.id).checked = true;
              $('input[type="submit"].epl-search-btn').trigger('click');
            }).on("select2:unselect", function (e) {
              document.getElementById(e.params.data.id).checked = false;
              $('input[type="submit"].epl-search-btn').trigger('click');
            });
            //   .on("select2:open", function (e) {
            //   $('.select2-selection__rendered > .select2-search--inline').css('display', 'none');
            // }).on("select2:close", function (e) {
            //   $('.select2-selection__rendered > .select2-search--inline').css('display', 'inline-block');
            // });
          }

          // If post type equals land, remove home type and more select elements from DOM
          // TODO: Hide bedrooms and bathrooms sliders with opacity 0 on land post type

          if (postType === 'Land') {
            $('select#home-type-criteria, select#more-criteria').remove();
          }

          // Interacting between map and search results page

          $('.tile > .tile__price > .page-price').each(function(){
            $(this).clone().insertAfter(this).text(priceConverter($(this).text()));
            $(this).hide();
          });

          $('.epl-listing-post > .epl-table-column-content > .price > .page-price').each(function(){
            $(this).clone().insertAfter(this).text(priceConverter($(this).text()));
            $(this).hide();
          });

            // Receive price of specific listing and call convert price function

          function markerPrice (id) {
            var curPrice = $( "a#post-" + id + " .page-price:first").text();
            return priceConverter(curPrice)
          }

            // Convert price in example from $2.000.000 to $2M

          function priceConverter (price) {
            if(price > 0 && price < 1000) {
              return '$' + price;
            } else if (price > 999 && price < 1000000) {
              return '$' + (price/1000).toFixed(0) + 'K';
            } else if (price > 999999) {
              return '$' + (price/1000000).toFixed(1).replace(/\.0$/, '') + 'M';
            }
          }

            // Set Up White Markers On Map Load

          myGmap.gmap3({
            get: {
              name:"marker",
              all: true,
              full: true,
              callback: function(markers){
                $.each(markers, function(i, marker){
                  marker.object.setIcon(createMarker(65, 30, 15, '#000000', markerPrice(marker.id)));
                });
                var curCoords = [parseFloat(localStorage.getItem("curCoordsLat")), parseFloat(localStorage.getItem("curCoordsLng"))];
                if (!Number.isNaN(curCoords[0]) || !Number.isNaN(curCoords[1])) {
                  myGmap.gmap3("get").setCenter({lat: curCoords[0], lng: curCoords[1]});
                  localStorage.clear();
                }

              }
            },
            map: {
              options: {
                zoom: localStorage.getItem("curZoom") !== null ? parseInt(localStorage.getItem("curZoom")) : 10
              },
              events: {
                'bounds_changed': function () {
                  var allMarkers = $(this).gmap3({get: {name:"marker",all: true}});
                  var bounds = [];
                  $.each(allMarkers, function(i, marker){
                    bounds.push(myGmap.gmap3("get").getBounds().contains(marker.getPosition()));
                  });
                  isMarkersOut = bounds.every(elem => elem != 1);
                  if (isMarkersOut) {
                    $('.update-search').show();
                  } else {
                    $('.update-search').hide();
                  }
                  return isMarkersOut;
                },
                'dragend': function(){
                  $(this).gmap3({
                    getaddress: {
                      latLng: [myGmap.gmap3("get").getCenter().lat(), myGmap.gmap3("get").getCenter().lng()],
                      callback: function (results) {
                        if (results.length) {
                          for (var i = 0; i < results[0].address_components.length; i++) {
                            for (var b = 0; b < results[0].address_components[i].types.length; b++) {

                              //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                              if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                                //this is the object you are looking for
                                states = results[0].address_components[i];
                                break;
                              } if (results[0].address_components[i].types[b] == "locality") {
                                //this is the object you are looking for
                                city = results[0].address_components[i];
                                break;
                              }
                              if (results[0].address_components[i].types[b] == "postal_code") {
                                //this is the object you are looking for
                                zip = results[0].address_components[i];
                                break;
                              }
                            }
                          }
                          var curCoordsLat = myGmap.gmap3("get").getCenter().lat();
                          var curCoordsLng = myGmap.gmap3("get").getCenter().lng();
                          localStorage.setItem("curCoordsLat", JSON.stringify(curCoordsLat));
                          localStorage.setItem("curCoordsLng", JSON.stringify(curCoordsLng));
                          localStorage.setItem("curZoom", myGmap.gmap3("get").getZoom());
                          $('input[type="text"]#property_address').attr('value', localStorage.getItem("curZoom") >= 12 ? city.short_name + ", " + states.short_name + " " + zip.short_name  : city.short_name + ", " + states.short_name);
                          $('input[type="submit"].epl-search-btn:last').trigger('click');
                        }
                      }
                    }
                  })
                },
                'zoom_changed': function(){
                  $(this).gmap3({
                    getaddress: {
                      latLng: [myGmap.gmap3("get").getCenter().lat(), myGmap.gmap3("get").getCenter().lng()],
                      callback: function (results) {
                        for (var i=0; i<results[0].address_components.length; i++) {
                          for (var b=0;b<results[0].address_components[i].types.length;b++) {

                            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                            if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                              //this is the object you are looking for
                              states = results[0].address_components[i];
                              break;
                            }
                            if (results[0].address_components[i].types[b] == "locality") {
                              //this is the object you are looking for
                              city = results[0].address_components[i];
                              break;
                            }
                            if (results[0].address_components[i].types[b] == "postal_code") {
                              //this is the object you are looking for
                              zip = results[0].address_components[i];
                              break;
                            }
                          }
                        }
                        var curCoordsLat = myGmap.gmap3("get").getCenter().lat();
                        var curCoordsLng = myGmap.gmap3("get").getCenter().lng();
                        localStorage.setItem("curCoordsLat", JSON.stringify(curCoordsLat));
                        localStorage.setItem("curCoordsLng", JSON.stringify(curCoordsLng));
                        localStorage.setItem("curZoom", myGmap.gmap3("get").getZoom());
                        $('input[type="text"]#property_address').attr('value', localStorage.getItem("curZoom") >= 12 ? city.short_name + ", " + states.short_name + " " + zip.short_name : city.short_name + ", " + states.short_name);
                        $('input[type="submit"].epl-search-btn:last').trigger('click');
                      }
                    }
                  })
                }
              }
            }
          });

          // Hide featured Listings on load if its empty

          if ($('.property-featured-image-wrapper').length){
            $('.featured-listing').show();
          } else {
            $('.featured-listing').hide();
          }
            // Change Icons On Hover On Search Result

          $( ".tile" ).each(function() {
            $(this).attr('target','_blank')
              .on("mouseenter", function(){
                var currentTiel = $(this).attr('id').replace(/[^0-9\.]/g, '');
                var marker = myGmap.gmap3({get:{name: "marker", id: currentTiel}});
                var map = myGmap.gmap3("get");
                marker.setIcon(createMarker(65, 30, 15, '#ad8625', markerPrice(currentTiel)));
                marker.setZIndex(200);
                // map.panTo(marker.getPosition());
              })
              .on("mouseleave", function(){
                var currentTiel = $(this).attr('id').replace(/[^0-9\.]/g, '');
                var marker = myGmap.gmap3({get:{name: "marker", id: currentTiel}});
                var map = myGmap.gmap3("get");
                marker.setIcon(createMarker(65, 30, 15, '#000000', markerPrice(currentTiel)));
                marker.setZIndex(100);
                // map.panTo(marker.getPosition());
              });
          });

            // Canvas Marker Icon Builder

          function createMarker(width, height, radius, color, text = "") {
            var canvas, context;
            canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            context = canvas.getContext("2d");
            context.font = "14px Helvetica, sans-serif";
            context.clearRect(0,0,width,height);
            context.fillStyle = color;
            context.beginPath();
            context.lineJoin = context.lineCap = 'round';
            context.moveTo(radius, 0);
            context.lineTo(width - radius, 0);
            context.quadraticCurveTo(width, 0, width, radius);
            context.lineTo(width, height-5 - radius);
            context.quadraticCurveTo(width, height-5, width - radius, height-5);
            context.lineTo(radius, height-5);
            context.quadraticCurveTo(0, height-5, 0, height-5 - radius);
            context.lineTo(0, radius);
            context.quadraticCurveTo(0, 0, radius, 0);
            context.moveTo(25,25);
            context.lineTo(32,30);
            context.lineTo(39,25);
            context.closePath();
            context.fill();
            context.textAlign = "center";
            context.fillStyle = "#ffffff";
            canvas.textBaseline = "center";
            context.fillText(text,width/2,height/1.75);
            return canvas.toDataURL();

          }

        });

      },
      // JavaScript to be fired on all pages, after page specific JS is fired
      finalize: function () {

      }
    },

    // Home page
    'home': {
      init: function () {
      },
      finalize: function () {
      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
      }
    },

    'sample_page': {
      init: function () {
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Penthouses;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        console.group(func + '.' + funcname + '()');
        namespace[func][funcname](args);
        console.groupEnd();
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
